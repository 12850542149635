mat-button-toggle-group {
	flex-wrap: wrap;
	border: 0px !important;
}
.typeContainer {
	margin-bottom: 20px;
}
mat-button-toggle {
	cursor: pointer;
	margin: 4px;
	border-radius: 3px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #4c4e52 !important;
	//   background-image: linear-gradient(
	//     to bottom left,
	//     #344675,
	//     #263148
	//   ) !important;
	font-family: "Poppins", sans-serif;
	font-size: 14px;
	font-weight: 600;
	// background-image: linear-gradient(
	//   to bottom left,
	//   #344675,
	//   #263148,
	//   #344675
	// ) !important;
}

.mat-button-toggle-group-appearance-standard
	.mat-button-toggle-appearance-standard
	+ .mat-button-toggle-appearance-standard {
	border-color: #4c4e52 !important;
}
.mat-button-toggle-checked {
	background-color: rgb(26, 38, 90) !important;
	background-image: linear-gradient(
		to bottom left,
		rgb(26, 38, 90),
		rgb(26, 38, 90)
	) !important;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
	line-height: 1.35em !important;
	font-size: 14px;
	font-family: "Poppins";
	font-weight: 600px;
	color: #ffffff;
}
.white-content {
	mat-button-toggle-group {
		flex-wrap: wrap;
		border: 0px;
	}
	.typeContainer {
		margin-bottom: 20px;
	}
	mat-button-toggle {
		margin: 4px;
		border-radius: 3px;
		padding-top: 5px;
		padding-bottom: 5px;
		background-color: rgba(240, 240, 240, 1);
		// background-image: linear-gradient(
		//   to bottom left,
		//   rgba(240, 240, 240, 1),
		//   rgb(216, 216, 216)
		// ) !important;
		font-family: "Poppins", sans-serif;
		font-size: 14px;
		font-weight: 600;
		// background-image: linear-gradient(
		//   to bottom left,
		//   #344675,
		//   #263148,
		//   #344675
		// ) !important;
	}
	.mat-button-toggle-checked {
		background-color: darken($white, 15%) !important;
		background-image: linear-gradient(
			to bottom left,
			rgb(195, 195, 195),
			rgb(170, 170, 170)
		) !important;
	}
	.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
		line-height: 1.35em !important;
		font-size: 14px;
		font-family: "Poppins";
		font-weight: 600px;
		color: lighten($black, 3%) !important;
	}
}
