/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
// @import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
// @import "~@ionic/angular/css/normalize.css";
// @import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
// @import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

// @import "~swiper/swiper-bundle";

$white: #fff !default;
.k-chart-surface {
	height: 100% !important;
}

pwa-camera-modal-instance {
	z-index: 1001;
}

.alert-wrapper.sc-ion-alert-ios {
	z-index: 1001;
}



@import "assets/scss/functions";
@import "assets/scss/mixins";
@import "assets/scss/variables";
@import "assets/scss/login";
@import "assets/scss/reboot";
@import "assets/scss/type";
@import "assets/scss/themes";
@import "assets/scss/buttons";
@import "assets/scss/slider";
//-- Angular Material
@import "assets/scss/mat/mat-card";
@import "assets/scss/mat/mat-dialog";
@import "assets/scss/mat/mat-drawer";
@import "assets/scss/mat/mat-form-field";
@import "assets/scss/mat/mat-sidenav";
@import "assets/scss/mat/mat-tab";
@import "assets/scss/mat/mat-table";
@import "assets/scss/mat/mat-toggle";
@import "assets/scss/mat/mat-expansion";
//-- cameras
@import "assets/scss/camera/camera-button";
@import "assets/scss/camera/camera-container";
@import "assets/scss/camera/camera-image";
//-- dashboard / widgets / gridster
@import "assets/scss/dashboard/gridster";
@import "assets/scss/dashboard/cdk";
@import "assets/scss/dashboard/widget-card";
//-- kendo UI
@import "assets/scss/kendoUI/kendoUI";
//-- File Upload
@import "assets/scss/file-upload/file-upload";
@import "assets/scss/file-upload/drop-area";
//-- asset-styles
@import "assets/scss/asset-styles";
//-- mobile-specific
@import "assets/scss/mobile-specific";
//-- toaster stying
@import "assets/scss/toaster";
//-- GSE styling
@import "assets/scss/gse";
//-- Card
@import "assets/scss/card";
//-- Summary Widget Styling
@import "assets/scss/summary/pca";
@import "assets/scss/summary/pbb";
@import "assets/scss/summary/gpu";
@import "assets/scss/summary/gse";
//-- Quick View Styling
@import "assets/scss/quick-view/pca";
@import "assets/scss/quick-view/pbb";
@import "assets/scss/quick-view/gpu";
@import "assets/scss/flex";
@import "assets/scss/grid";
@import "assets/scss/utilities/display"

// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.
