mat-button-toggle-group {
	flex-wrap: wrap;
	border: 0px !important;
}

.typeContainer {
	margin-bottom: 20px;
}

mat-button-toggle {
	cursor: pointer;
	margin: 4px;
	border-radius: 3px;
	padding-top: 11px;
	padding-bottom: 11px;
	background-color: #4c4e52 !important;
	font-family: "Poppins", sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.mat-button-toggle-group-appearance-standard
	.mat-button-toggle-appearance-standard + .mat-button-toggle-appearance-standard {
	border-color: rgb(48, 63, 103) !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
	line-height: 1.35em !important;
	font-size: 14px;
	font-family: "Poppins";
	font-weight: 600px;
	color: #ffffff;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
	color: white !important;
}

.white-content {
	mat-button-toggle-group {
		flex-wrap: wrap;
		border: 0px;
	}

	.typeContainer {
		margin-bottom: 20px;
	}

	mat-button-toggle {
		margin: 4px;
		border-radius: 3px;
		padding-top: 11px;
		padding-bottom: 11px;
		background-color: #ffffff !important;
		font-family: "Poppins", sans-serif;
		font-size: 14px;
		font-weight: 600;
	}

	.mat-button-toggle-group-appearance-standard
	.mat-button-toggle-appearance-standard	+ .mat-button-toggle-appearance-standard {
		border-color: rgb(240, 240, 240) !important;
	}

	.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
		line-height: 1.35em !important;
		font-size: 14px;
		font-family: "Poppins";
		font-weight: 600px;
		color: lighten($black, 3%) !important;
	}

	.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
	.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
		color: #27304c !important;
	}
}
