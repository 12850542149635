.mat-dialog-container {
  padding: 0px !important;
	background: $card-black-background;
	.ios {
        color: #fff;
      --ion-text-color: #fff;
      }

      /* Set text color of the entire app for Material Design only */
      .md {
          color: #eee;
        --ion-text-color: #eee;
	  }
}

.mat-icon,
.mdc-button__label {
	color: var(--body-text-color-light);
}

.white-content {
	.mat-dialog-container {
		padding: 0px !important;
		background: #ffffff !important;
	}

	.mat-icon,
	.mdc-button__label {
		color: #000 !important;
      --ion-text-color: #000 !important;
	}

	.ios {
        color: #000 !important;
      --ion-text-color: #000 !important;
      }

      /* Set text color of the entire app for Material Design only */
      .md {
          color: #222 !important;
        --ion-text-color: #222 !important;
      }
}

.no-overflow > mat-dialog-container {
	overflow: hidden !important;
}

.mat-dialog {
	transition: none !important;
}

.close-icon {
	float: right !important;
	padding: 0px !important;
	margin-right: 20px !important;
	margin-left: 20px !important;
}

.mat-menu-content {
    background: #616161e6;
}

.mat-menu-item{
    color: #FFFFFF
}


