.asset {
    display: inline !important;
    text-align: center;
	cursor: pointer;
	padding: 5px !important;
	margin: 1px !important;
}

.out-of-service {
	display: inline !important;
    text-align: center;
	cursor: pointer;
	padding:0 5px !important;
	//min-width: 12px !important;
	margin: 1px !important;
}

.out-of-service-container {
	padding: 2px 0;
}

.asset-container {
	display: inline !important;
	text-align: center;
}

.unit-on {
	background-color: limegreen;
	color: white;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
	font-size: medium;
  }


.unit-off {
	background-color: darkgrey;
	color: white;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
	font-size: medium;
}

.fleet-amber {
	background-color: #FFBF00;
	color: black !important;
	border-radius: 10px;
	border: 2px solid lightsteelblue;
}

.fleet-alarm{
	background-color: yellow;
	color: black !important;
	border-radius: 10px;
	border: 2px solid lightsteelblue;
}

.fleet-critical {
	background-color: red;
	color: white;
	border: 2px solid lightsteelblue;
	border-radius: 10px;
	animation: criticalAlarmBorderBlinker 1s linear infinite;
}

.fleet-alert {
	background-color: orange;
	color: black !important;
	border-radius: 10px;
	border: 2px solid lightsteelblue;
}



.unit-alarm {
	background-color:   red;
  }

.unit-on, .pca-unit-on-not-bridge-mode, .gpu-unit-on-and-switched-over {
	background-color: limegreen;
	color: white;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
	font-size: medium;
}

.pca-unit-on-bridge-mode, .gpu-unit-on-and-not-switched-over {
	background-color:  mediumpurple;
	color: white;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
	font-size: medium;

}

.alarm-inactive {
	border: 2px solid lightsteelblue;
}

.alarm-active {
	border: 2px solid red;
}

.critical-alarm-active {
	border: 2px solid red;
	animation: criticalAlarmBorderBlinker 1s linear infinite;
}

.hmi-on{
	color: limegreen;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
}

.hmi-off{
	color: darkgrey;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
}

.hmi-gpu-unit-on-and-not-switched-over,.hmi-pca-unit-on-bridge-mode{
	color: mediumpurple;
	font-family: 'Orbitron', sans-serif;
	font-weight: 900;
}

.hmi-alarm{
	color: red;
	font-family: 'Orbitron', sans-serif;
	font-weight: 800;
}

.hmi-critical-alarm{
	color: red;
	font-family: 'Orbitron', sans-serif;
	font-weight: 800;
	animation: criticalAlarmBlinker 1s linear infinite;

}

@keyframes criticalAlarmBlinker {
	50% {
	  opacity: 0;
	}
  }

  @keyframes criticalAlarmBorderBlinker {
	50% {
	 border-color: white
	}
  }



.white-content {
	.unit-off {
		background-color: lightgrey;
	}

	.unit-on, .pca-unit-on-not-bridge-mode, .gpu-unit-on-and-switched-over {
		background-color:  rgb(121, 255, 77);
	}

	.pca-unit-on-bridge-mode, .gpu-unit-on-and-not-switched-over {
		background-color:  #b3b3ff;
	}
}

.alarmFaults {
	display: inline-block;
	background-color: yellow;
	color: black !important;
	border-radius: 50%;
	height: 35px;
	width: 35px;
	text-align: center;
	padding: 8px;
}

.criticalAlarmFaults {
	display: inline-block;
	background-color: rgba(255, 0, 0, 1);
	color: white !important;
	border-radius: 50%;
	height: 35px;
	width: 35px;
	text-align: center;
	padding: 8px;
}

.noFaults {
	background-color: green;
	color: white;
	border-radius: 50%;
	height: 35px;
	width: 35px;
	text-align: center;
	padding: 8px;
}
