.mat-mdc-tab-body-wrapper {
	height: 100% !important;
}

.mat-mdc-tab {
	height: 30px !important;
}

.mdc-tab__text-label {
	color: white !important;
}

.white-content {
	.mdc-tab__text-label {
		color: $input-color;
	}
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
	> .mat-mdc-tab-header
	.mat-mdc-tab {
	flex-grow: 0 !important;
}

.mat-tab-label,
.mat-tab-link {
	color: $input-color;
}

.mat-tab-body,
.mat-tab-body-wrapper,
.mat-tab-group {
	height: calc(100%);
}

.mat-tab-label,
.mat-tab-label-active {
	min-width: 0 !important;
	padding-left: 3px !important;

	padding-right: 30px !important;

	//   margin: 3px !important;
}

.mat-tab-label {
	height: 30px !important;
}

.mat-tab-body-content {
	overflow: hidden !important;
	//   margin: 5px;
}

.warningBadge .mat-badge-content {
	top: -5px !important;
	background-color: rgba(255, 140, 0, 0.6);
}

.alarmBadge .mat-badge-content {
	top: -5px !important;
	background-color: red;
}

.criticalAlarmBadge .mat-badge-content {
	top: -5px !important;
	background-color: rgba(255, 0, 0, 1);
	animation: criticalAlarmBadge 1s step-end infinite;
	/* add 'border-color: transparent' if you wish no border to show initially */
}

@-webkit-keyframes criticalAlarmBadge {
	from,
	to {
		background-color: transparent;
	}
	50% {
		background-color: rgba(255, 0, 0, 0.5);
	}
	100% {
		background-color: rgba(255, 0, 0, 1);
	}
}
@keyframes criticalAlarmBadge {
	from,
	to {
		background-color: transparent;
	}
	50% {
		background-color: rgba(255, 0, 0, 0.5);
	}
	100% {
		background-color: rgba(255, 0, 0, 1);
	}
}
